@font-face {
  font-family: 'Rajdhani';
  src: url('./fonts/Rajdhani-Medium.woff2') format('woff2'),
       url('./fonts/Rajdhani-Medium.woff') format('woff');
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: 'Rajdhani';
  src: url('./fonts/Rajdhani-SemiBold.woff2') format('woff2'),
       url('./fonts/Rajdhani-SemiBold.woff') format('woff');
  font-weight: 600;
  font-display: swap;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  :root {
    --background: 0 0% 100%;
    --foreground: 222.2 47.4% 11.2%;
 
    --muted: 210 40% 96.1%;
    --muted-foreground: 215.4 16.3% 46.9%;
 
    --popover: 0 0% 100%;
    --popover-foreground: 222.2 47.4% 11.2%;
 
    --border: 214.3 31.8% 91.4%;
    --input: 214.3 31.8% 91.4%;
 
    --card: 0 0% 100%;
    --card-foreground: 222.2 47.4% 11.2%;
 
    --primary: 222.2 47.4% 11.2%;
    --primary-foreground: 210 40% 98%;
 
    --secondary: 210 40% 96.1%;
    --secondary-foreground: 222.2 47.4% 11.2%;
 
    --accent: 210 40% 96.1%;
    --accent-foreground: 222.2 47.4% 11.2%;
 
    --destructive: 0 100% 50%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 215 20.2% 65.1%;
 
    --radius: 0.5rem;
  }
 
  .dark {
    --background: 224 71% 4%;
    --foreground: 213 31% 91%;
 
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
 
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
 
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
 
    --border: 216 34% 17%;
    --input: 216 34% 17%;
 
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
 
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
 
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
 
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
 
    --ring: 216 34% 17%;
 
    --radius: 0.5rem;
  }
}
 
@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
    font-feature-settings: "rlig" 1, "calt" 1;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .scrollbar-hide::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .scrollbar-hide {
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */
  }

  .backdrop-blur-md {
    backdrop-filter: blur(12px);
    -webkit-backdrop-filter: blur(12px);
  }

  .text-cyber {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-shadow: 0 0 8px rgba(34, 197, 94, 0.4),
                 0 0 15px rgba(34, 197, 94, 0.2),
                 0 0 1px rgba(255, 255, 255, 0.6);
    color: rgb(34, 197, 94);
  }

  .text-cyber-warning {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-shadow: 0 0 10px rgba(249, 115, 22, 0.5),
                 0 0 20px rgba(249, 115, 22, 0.3),
                 0 0 1px rgba(255, 255, 255, 0.8);
    color: rgb(249, 115, 22);
  }

  @keyframes cyber-glow-warning {
    0% {
      text-shadow: 0 0 10px rgba(249, 115, 22, 0.5),
                   0 0 20px rgba(249, 115, 22, 0.3);
    }
    50% {
      text-shadow: 0 0 15px rgba(249, 115, 22, 0.7),
                   0 0 25px rgba(249, 115, 22, 0.5);
    }
    100% {
      text-shadow: 0 0 10px rgba(249, 115, 22, 0.5),
                   0 0 20px rgba(249, 115, 22, 0.3);
    }
  }

  .animate-cyber-glow-warning {
    animation: cyber-glow-warning 2s ease-in-out infinite;
  }

  @keyframes cyber-glow {
    0% {
      text-shadow: 0 0 10px rgba(34, 197, 94, 0.5),
                   0 0 20px rgba(34, 197, 94, 0.3);
    }
    50% {
      text-shadow: 0 0 15px rgba(34, 197, 94, 0.7),
                   0 0 25px rgba(34, 197, 94, 0.5);
    }
    100% {
      text-shadow: 0 0 10px rgba(34, 197, 94, 0.5),
                   0 0 20px rgba(34, 197, 94, 0.3);
    }
  }

  .animate-cyber-glow {
    animation: cyber-glow 2s ease-in-out infinite;
  }

  .btn-cyber {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(22, 163, 74, 0.95),
      rgba(34, 197, 94, 0.85)
    );
    color: white;
    padding: 0.25rem 0.75rem;
    border-radius: 0.375rem;
    border: 1px solid rgba(34, 197, 94, 0.4);
    box-shadow: 0 0 8px rgba(34, 197, 94, 0.4),
                0 0 15px rgba(34, 197, 94, 0.2),
                inset 0 0 8px rgba(34, 197, 94, 0.1);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    font-size: 0.875rem;
  }

  .btn-cyber:hover {
    background: linear-gradient(
      90deg,
      rgba(34, 197, 94, 1),
      rgba(22, 163, 74, 0.9)
    );
    box-shadow: 0 0 12px rgba(34, 197, 94, 0.5),
                0 0 20px rgba(34, 197, 94, 0.3),
                inset 0 0 12px rgba(34, 197, 94, 0.2);
    transform: translateY(-1px);
  }

  .btn-cyber::before {
    content: '';
    position: absolute;
    inset: -2px;
    border-radius: 0.475rem;
    background: linear-gradient(
      45deg,
      rgba(34, 197, 94, 0.5),
      transparent,
      rgba(34, 197, 94, 0.5)
    );
    mask: 
      linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    mask-composite: exclude;
    pointer-events: none;
    animation: cyber-border-flow 4s linear infinite;
  }

  @keyframes cyber-border-flow {
    0% {
      background-position: 0% 0%;
    }
    100% {
      background-position: 400% 0%;
    }
  }

  /* Add these new animations */
  @keyframes grid-flow {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(40px);
    }
  }

  .animate-grid-flow {
    animation: grid-flow 20s linear infinite;
  }

  @keyframes cyber-pulse {
    0% {
      opacity: 0.5;
    }
    50% {
      opacity: 0.8;
    }
    100% {
      opacity: 0.5;
    }
  }

  .animate-cyber-pulse {
    animation: cyber-pulse 2s ease-in-out infinite;
  }

  .bg-grid-pattern {
    background-image: 
      linear-gradient(to right, rgba(45, 212, 191, 0.1) 1px, transparent 1px),
      linear-gradient(to bottom, rgba(45, 212, 191, 0.1) 1px, transparent 1px);
    background-size: 20px 20px;
  }

  .text-cyber-orange {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    letter-spacing: 0.05em;
    text-shadow: 0 0 8px rgba(245, 158, 11, 0.4),
                 0 0 15px rgba(245, 158, 11, 0.2),
                 0 0 1px rgba(255, 255, 255, 0.6);
    color: rgb(251, 146, 60);
  }

  @keyframes cyber-glow-orange {
    0% {
      text-shadow: 0 0 10px rgba(245, 158, 11, 0.5),
                   0 0 20px rgba(245, 158, 11, 0.3);
    }
    50% {
      text-shadow: 0 0 15px rgba(245, 158, 11, 0.7),
                   0 0 25px rgba(245, 158, 11, 0.5);
    }
    100% {
      text-shadow: 0 0 10px rgba(245, 158, 11, 0.5),
                   0 0 20px rgba(245, 158, 11, 0.3);
    }
  }

  .animate-cyber-glow-orange {
    animation: cyber-glow-orange 2s ease-in-out infinite;
  }

  /* Narrower version of the cyber button */
  .btn-cyber-narrow {
    font-family: 'Rajdhani', sans-serif;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.1em;
    position: relative;
    background: linear-gradient(
      90deg,
      rgba(22, 163, 74, 0.95),
      rgba(34, 197, 94, 0.85)
    );
    color: white;
    padding: 0.22rem 0.65rem;  /* Less extreme padding reduction */
    border-radius: 0.375rem;
    border: 1px solid rgba(34, 197, 94, 0.4);
    box-shadow: 0 0 8px rgba(34, 197, 94, 0.4),
                0 0 15px rgba(34, 197, 94, 0.2),
                inset 0 0 8px rgba(34, 197, 94, 0.1);
    text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
    backdrop-filter: blur(4px);
    font-size: 0.85rem;  /* Less extreme font size reduction */
  }

  .btn-cyber-narrow:hover {
    background: linear-gradient(
      90deg,
      rgba(34, 197, 94, 1),
      rgba(22, 163, 74, 0.9)
    );
    box-shadow: 0 0 15px rgba(34, 197, 94, 0.6),
                0 0 25px rgba(34, 197, 94, 0.4),
                inset 0 0 12px rgba(34, 197, 94, 0.2);
    transform: translateY(-1px);
  }

  @keyframes subtle-pulse {
    0%, 100% {
      box-shadow: 0 0 8px rgba(34, 197, 94, 0.4),
                  0 0 15px rgba(34, 197, 94, 0.2),
                  inset 0 0 8px rgba(34, 197, 94, 0.1);
    }
    50% {
      box-shadow: 0 0 10px rgba(34, 197, 94, 0.45),
                  0 0 18px rgba(34, 197, 94, 0.25),
                  inset 0 0 9px rgba(34, 197, 94, 0.15);
    }
  }

  .btn-cyber-narrow::before {
    content: '';
    position: absolute;
    inset: -2px;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-up {
  animation: fade-up 0.3s ease-out forwards;
}
