@tailwind base;
@tailwind components;
@tailwind utilities;

@keyframes typing {
    0% { content: ''; }
    25% { content: '.'; }
    50% { content: '..'; }
    75% { content: '...'; }
    100% { content: ''; }
  }
  
  .typing-animation::after {
    content: '';
    animation: typing 1.5s infinite;
  }